<template>

    <div v-if="modal_open" transition="fadein" class="fixed z-50 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gp_black-default-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-gp_black-default-500 border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div class="flex justify-end mb-2 text-gp_pink-default-500">
                        <XIcon @click="toggleModal()" class="hover:text-gp_pink-default-400 cursor-pointer"/>
                    </div>
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-6 px-6 md:px-12 justify-items-center text-center">
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase mt-6" id="modal-title">Return to platform</h3> 
                            <p class="text-gray-100 font-bold">
                                By clicking 'Return now', you will lose all progress saved up to this point.
                            </p>

                            <div class="flex flex-wrap gap-6 justify-center items-center">
                                <div @click="returnToPlatform()" class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 cursor-pointer my-2 md:my-6 py-2 px-8 rounded-lg flex space-x-4">
                                    <span class="text-white">Return now</span>
                                    <ArrowNarrowRightIcon class="text-white" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>

import { ArrowNarrowRightIcon, XIcon } from "@vue-hero-icons/outline"

    export default
    {
        name: "ReturnToPlatform", 
        components:{
            ArrowNarrowRightIcon, XIcon
        },
        data(){
            return {
                modal_open: false, 
            }
        },
        methods: {
            toggleModal() {
                this.modal_open = !this.modal_open;
            },
            returnToPlatform() {
                sessionStorage.removeItem('from_add_venue');
                this.$router.push('/dashboard');
            }
        }
    }

</script>